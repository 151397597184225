import { LegalSystem as MyType } from '@shapeable/lli-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';

export const IndicatorGroup: EntityConfig<MyType> = {
  name: 'IndicatorGroup',
  label: 'Indicator Group',
  type: 'taxonomy',
  includeInSearch: false,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
    }
  },
}; 