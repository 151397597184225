import { EconomyLegalSystem as MyType } from '@shapeable/lli-types';
import { EntityConfig, entityCoreFormFields } from '@shapeable/core';

export const EconomyLegalSystem: EntityConfig<MyType> = {
  name: 'EconomyLegalSystem',
  label: 'Country Legal System',
  type: 'child',
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update'],
  form: {
    fields: {
      ...entityCoreFormFields,
      legalSystem: { type: 'linked', entityTypeName: 'LegalSystem', variant: 'forEconomy' },
      economy: { type: 'linked', entityTypeName: 'Economy', variant: 'forLegalSystem' },
      description: { type: 'longText' },
    }
  },
}; 