import { EconomyMethodology as MyType } from '@shapeable/lli-types';
import { EntityConfig, entityCoreFormFields } from '@shapeable/core';

export const EconomyMethodology: EntityConfig<MyType> = {
  name: 'EconomyMethodology',
  label: 'Country Methodology',
  type: 'child',
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update'],
  form: {
    fields: {
      ...entityCoreFormFields,
      economy: { type: 'linked', entityTypeName: 'Economy' },
      methodology: { type: 'linked', entityTypeName: 'Methodology' },
      description: { type: 'longText' },
      files: { type: 'linkedMultiple', entityTypeName: 'FileAsset' },
      links: { type: 'linkedMultiple', entityTypeName: 'Link' },
      videos: { type: 'linkedMultiple', entityTypeName: 'Video' },
      feedEntries: { type: 'linkedMultiple', entityTypeName: 'FeedEntry' },
    }
  },
}; 