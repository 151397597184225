import { OrganisationEconomyRole as MyType } from '@shapeable/lli-types';
import { EntityConfig, entityCoreFormFields } from '@shapeable/core';

export const OrganisationEconomyRole: EntityConfig<MyType> = {
  name: 'OrganisationEconomyRole',
  label: 'Organisation Country Role',
  type: 'child',
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update'],
  form: {
    fields: {
      ...entityCoreFormFields,
      economy: { type: 'linked', entityTypeName: 'Economy', variant: 'forOrganisation' },
      organisation: { type: 'linked', entityTypeName: 'Organisation' },
      role: { type: 'linked', entityTypeName: 'Role' },
      description: { type: 'longText' },
    }
  },
}; 