import { LegalSystem as MyType } from '@shapeable/lli-types';
import { EntityConfig, entityStandardFormFields } from '@shapeable/core';

export const LegalSystem: EntityConfig<MyType> = {
  name: 'LegalSystem',
  label: 'Legal System',
  type: 'taxonomy',
  includeInSearch: true,
  list: {
    resultsVariant: 'list',
  },
  autoRoutes: ['create', 'update', 'list'],
  form: {
    fields: {
      ...entityStandardFormFields,
      description: { type: 'longText' },
    }
  },
}; 