import { createApplication, entityConfigs } from '@shapeable/core';
import { themes } from '@shapeable/theme';
import { includes, pickBy, omitBy } from 'lodash';
import { ApplicationLogo, ApplicationLogoInverted } from './components';

const theme = themes.JUSTICE_THEME;

import { EconomyLegalSystem, EconomyMethodology, EconomyRole, IndicatorGroup, LegalSystem, Methodology, OrganisationEconomyRole } from './config';
import { ProjectStatus } from '@shapeable/core/dist/config/entities';

const { Economy, FeedEntry, Indicator, Page, Post, Video, Site, Person, Organisation, FileAsset, Task, Workstream, Trend } = entityConfigs;

if (FeedEntry) {
  FeedEntry.extraFieldSets = [
    { name: 'connections', label: 'Connections' },
  ];

  FeedEntry.extraFields = {
    legalSystems: { type: 'linkedMultiple', entityTypeName: 'LegalSystem', fieldSet: 'connections' },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'Methodology', fieldSet: 'connections' },
  };
}

if (Page) {
  Page.extraFields = {
    featurePosts: { type: 'linkedMultiple', label: 'Feature Posts', entityTypeName: "Post", fieldSet: "content" },
  }
}

if (Post) {
  Post.extraFieldSets = [
    { name: 'connections', label: 'Connections' },
  ];

  Post.extraFields = {
    economies: { type: 'linkedMultiple', entityTypeName: 'Economy' },
    legalSystems: { type: 'linkedMultiple', entityTypeName: 'LegalSystem', fieldSet: 'connections' },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'Methodology', fieldSet: 'connections' },
    
  };
}

if (Video) {
  Video.extraFields = {
    economies: { type: 'linkedMultiple', entityTypeName: 'Economy' },
  };
}

if (Site) { 
  Site.extraFields = {
    economies: { type: 'linkedMultiple', entityTypeName: 'Economy'},
  };
}

if (Indicator) { 
  Indicator.extraFields = {
    group: { type: 'linked', entityTypeName: 'IndicatorGroup' },
  };
}

if (Person) { 
  Person.extraFields = {
    memberOfEconomies: { type: 'linkedMultiple', fieldSet: 'admin', label: 'Member of Economies', description: 'Which {{ECONOMY}} dashboards does this {{PERSON}} have access to on the Decriminalise Suicide Website dashboard', entityTypeName: 'Economy' },
    economyRoles: { type: 'linkedTable', entityTypeName: 'EconomyRole', fieldSet: 'roles' },
  };
}

if (Organisation) { 
  Organisation.extraFields = {
    economyRoles: { type: 'linkedMultiple', entityTypeName: 'OrganisationEconomyRole' },
  };
}

if (Workstream) { 
  Workstream.extraFields = {
    outlineNumber: { type: 'text' },
    indicatorValues: { type: 'linkedMultiple', entityTypeName: 'IndicatorValue' },
    methodologies: { type: 'linkedMultiple', entityTypeName: 'EconomyMethodology' },
  };
}

if (Task) { 
  Task.extraFields = {
    outlineNumber: { type: 'text' },
  };
}


if (Economy) {

  Economy.extraFieldSets = [
    { name: 'content', label: 'Content' },
    { name: 'community', label: 'Community' },
    { name: 'indicators', label: 'Indicators' },
    { name: 'library', label: 'Library' },
    { name: 'legal-methodologies', label: 'Legal Systems & Methodologies' },
  ];

  Economy.extraFields = {

    intro: { type: 'longText', fieldSet: 'content' },
    theLegalSystem: { type: 'longText', fieldSet: 'content' },
    provisionsActsAndPunishments: { type: 'longText', fieldSet: 'content' },
    culturalSocialAndReligiousContext: { type: 'longText', fieldSet: 'content' },
    advocacyAndStakeholders: { type: 'longText', fieldSet: 'content' },
    legislativeReformAndDevelopments: { type: 'longText', fieldSet: 'content' },
    howToChangeTheLaw: { type: 'longText', fieldSet: 'content' },
    theEffectsOfChange: { type: 'longText', fieldSet: 'content' },
    conclusion: { type: 'longText', fieldSet: 'content' },

    files: { type: 'linkedMultiple', entityTypeName: 'FileAsset', fieldSet: 'library' },
    status: { type: 'linked', entityTypeName: 'ProjectStatus' },

    sites: { type: 'linkedMultiple', fieldSet: 'admin', entityTypeName: 'Site' },

    economyLegalSystems: { type: 'linkedTable', entityTypeName: 'EconomyLegalSystem', fieldSet: 'legal-methodologies' },
    economyMethodologies: { type: 'linkedTable', entityTypeName: 'EconomyMethodology', fieldSet: 'legal-methodologies' },

    roles: { type: 'linkedTable', entityTypeName: 'EconomyRole', fieldSet: 'community' },
    organisationRoles: { type: 'linkedTable', entityTypeName: 'OrganisationEconomyRole', fieldSet: 'community'  },
    
    workstreams: { type: 'linkedTable', entityTypeName: 'Workstream', fieldSet: 'activity' },
    indicatorValues: { type: 'linkedTable', entityTypeName: 'IndicatorValue', fieldSet: 'indicators' },
    spokenLanguages: { type: 'linkedMultiple', entityTypeName: 'Language' },

    isPriority: { type: 'boolean', fieldSet: 'admin' },
    isLliMember: { type: 'boolean', fieldSet: 'admin' },
  };
}

if (FileAsset) {
  FileAsset.extraFields = {
  };
}

delete Economy.form.fields.impactResults;
delete Economy.form.fields.quotes;
delete Economy.form.fields.population;
delete Economy.form.fields.areaInSquareKm;
delete Economy.form.fields.densityPerSquareKm;
delete Economy.form.fields.gdp;
delete Workstream.form.fields.team;




export const application = createApplication({
  theme,
  entityConfigs: {
    ...omitBy(entityConfigs, config => includes(["Trend", "Topic", "SubTopic", "Challenge", "KeyIssue", "Chapter", "ImpactStory", "Event"], config.name)),
    EconomyLegalSystem, 
    EconomyMethodology, 
    EconomyRole, 
    IndicatorGroup,
    LegalSystem, 
    Methodology, 
    OrganisationEconomyRole,
  },
  options: {
    systemsMapping: 'full',
  },
  components: {
    Logo: ApplicationLogo,
    LogoInverted: ApplicationLogoInverted,
  },
});

/* ---- Patch Entities definitions afterwards to ensure sub-props are already set --- */

const entities = application.entities;
